@import '~styles/variables';

.headerWrapper {
  background-color: $medikit-neutral1;
  padding: 24px 0px;
}

.titleWrapper {
  background-color: $medikit-white;
  padding: 40px 40px 0px 40px;
}

.copyWrapper {
  padding: 25px 40px 0px 40px;
  background-color: $medikit-neutral1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.infoWrapper {
  background-color: $medikit-white;
  padding: 0px 40px 0px 40px;
}

.infoCopyWrapper {
  padding: 0px 40px 0px 40px;
  background-color: $medikit-neutral1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.buttonWrapper {
  background-color: $medikit-white;
  padding: 60px 40px 40px 40px;
  z-index: 1;
}

.nextButton {
  width: 295px;
}

.contentWrapper {
  padding-top: 32px;
  padding-bottom: 100px;
}

.closeButtonCell {
  text-align: right;
  padding-top: 4px;
}

.closeButton {
  cursor: pointer;
}