@import '~styles/variables';

.detailWrapper {
  padding-top: 24px;
}

.detailBlock {
  background-color: $medikit-white;
  padding: 30px 40px 40px 40px;
}

.fieldRow {
  padding-top: 24px;
}

.button {
  padding: 3px 20px 1px;
  font-size: 14px;
  width: 160px;
  margin-top: 32px;
}

.alertWrapper {
  margin-bottom: -20px;
}