@import '~styles/variables';

.column {
  padding-left: 0px;
  padding-right: 0px;
}

.alertWrapper {
  padding-bottom: 20px;
}

.alertTitle {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 16px;
}

.alertColor:before {
  background-color: $medikit-highlight2;
}

.alertIconColor {
  color: $medikit-highlight2;
}
