.primary-nav-category {
  display: inline-block;

  .nav-link.active {
    border-bottom: 2px solid #e62e43;
  }
}

.account-nav-item.active{
  border-left: 3px solid $medikit-primary-dark;
}
