@import '~styles/variables';

.linesTable {
  background-color: $medikit-white;
  color: $medikit-black;
  font-size: 14px;
  line-height: 17px;
  border-spacing: 0;
  width: 100%;

  td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
  }
}