.cancelButton {
  margin-top: 10px;
  height: 40px;
  width: 140px;
  border: 2px solid #111826;
  border-radius: 1px;

  background-color: rgba(255,255,255,0);

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

