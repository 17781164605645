@import '~styles/variables';

.centerSpinner {
  text-align: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(256,256,256,0.65);
  z-index: 500;
  padding-top: 30px;
}

.clearOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  padding-top: 30px;
}

.darkerSpinner {
  color: $medikit-neutral7;
}


