/***
 Removing top-margin from headings to prevent collapsing margins
 from messing around with page layout
 */

h1,h2,h3,h4,h5,h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 8px;
}

.pointer {
  cursor: pointer;
}

.simple-link {
  cursor: pointer;
  color: $medikit-highlight2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.simple-link-reverse {
  cursor: pointer;
  color: $medikit-highlight2;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.error-message-link {
  color: $medikit-primary-dark;
}