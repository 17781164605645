@import '~styles/variables';

.detailBlock {
  background-color: $medikit-white;
  margin-top: 24px;
}

.detailHeader {
  background-color: $medikit-highlight2;
  color: $medikit-white;
  padding: 10px 24px 5px 24px;
}

.detailLeft {
  background-color: $medikit-white;
  padding: 0px 0px 40px 40px;
}

.detailLeftOldPwd {
  background-color: $medikit-white;
  padding: 30px 0px 0px 40px;
}

.detailRight {
  padding: 25px 40px 40px 40px;
  background-color: $medikit-neutral1;
  height: 100%;
}

.confirmPwdRow {
  padding-top: 24px;
}

.errorRow {
  color: $medikit-primary-dark;
  padding-top: 16px;
}

.buttonRow {
  margin-top: 32px;
  button {
    width: 160px;
    margin-right: 24px;
    padding: 5px 40px 3px;
  }
}