.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal[aria-hidden=true] {
  display: none;
}

.modal[aria-hidden=false] {
  display: block;
}

.modal-overlay.hidden {
  display:none;
}

// Custom media queries to get modal with to match bootstrap .container width
.modal-dialog {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1140px;
  }
}



