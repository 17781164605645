@import '~styles/variables';

.titleWrapper {
  background-color: $medikit-white;
  padding: 40px 40px 0px 40px;
}

.copyWrapper {
  padding: 0px 40px 0px 40px;
  background-color: $medikit-neutral1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.fieldsWrapper {
  background-color: $medikit-white;
  padding: 0px 40px 0px 40px;
  z-index: 2;
}

.buttonWrapper {
  background-color: $medikit-white;
  padding: 10px 40px 40px 40px;
  z-index: 1;
}

.contentWrapper {
  padding-top: 32px;
  padding-bottom: 100px;
}







