@import '~styles/variables';

.mainWrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contentWrapper {
  background-color: white;
  padding: 0;
}

.sectionHeader {
  margin-top: 2em;
}

.detailsBox {
  display: flex;
  justify-content: space-evenly;
  padding-left: 0.5em;
  h5 {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 1em;
  }
}

.providerName {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}

.groupHeader {
  padding: 10px 40px;
  background-color: $medikit-neutral1;
  width: 100%;

  h4 {
    margin-top: 0.8rem;
  }
}
.awaiting {
  border-left: 4px solid $medikit-highlight3;
}

.declined {
  border-left: 4px solid $medikit-primary-darker;
}

.accepted {
  border-left: 4px solid $medikit-accent;
}

.stemBox {
  margin-top: 1.5em;
}

.buttonDiv {
  justify-content: flex-end;
  display: flex;
  padding-right: 0;
  button {
    margin: auto 0;
    height: 48px;
  }

}

.stemDiv {
  padding-left: 0;
}

.resultsWrapper {
  background-color: $medikit-white;
  margin-bottom: 0;
}

.providerTable {
  margin-bottom: 0px;
}

.th1 {
  width: 160px;
  min-width: 160px;
}

.th2 {
  width: auto;
  min-width: 400px;
}

.th3 {
  width: 255px;
  min-width: 255px;
}

.noConnectedWrapper {
  background-color: $medikit-white;
  justify-content: center;
  margin-top: 15px;
  padding: 25px 0px;
}

.addBtn {
  width: 220px;
}


