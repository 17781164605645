@import '~styles/variables';

.mainWrapper {
  padding-top: 40px;
  padding-bottom: 128px;
}


.alertsContainer {
  margin: 0;
}

