@import '~styles/variables';

.contentWrapper {
    padding-top: 80px;
    padding-bottom: 100px;

    hr {
        margin: 7px 0;
    }
}

.colPadding{
    background-color: $medikit-white;
    padding-right: 0px;
    padding-left: 0px;
}

.sidebarWrapper {
    padding-right: 0px;
    padding-left: 0px;
}

.messageWrapper {
    padding-left: 0px;
    padding-right: 0px;
}