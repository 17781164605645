/***
 * Specific styling for Tabs for the provider portal.
 */

.nav.pp-nav-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-item {
    width: 225px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-item .nav-link {
    background-color: $medikit-neutral6;
    color: $medikit-white;
  }

  li.nav-item:first-child {
    margin-top: 8px;
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: bold;
  }

  .nav-link {
    display: block;
    padding: 1rem 1rem;
  }
}

.pp-tab-content {
  background-color: $medikit-white;
  padding: 30px 40px 30px 40px;

  .tab-content-pane {
    margin-top: 0;
  }
}
