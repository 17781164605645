@import '~styles/variables';

.header {
  height: 150px;
  background-color: $medikit-highlight2-dark;
  padding: 32px;
}

.iconWrapper {
  color: $medikit-white;
  height: 48px;
  width: 48px;
}

.title {
  margin-top: 15px;
  font-size: 21px;
  color: $medikit-white;
}

.body {
  height: 200px;
  background-color: $medikit-white;
  padding: 32px;
}

.footer {
  background-color: $medikit-white;
  padding: 32px;
  text-align: center;

  button {
    width: 100%;
  }
}