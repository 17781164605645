@import '~styles/variables';

.stepsContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.step {
  flex-shrink: 1;
  font-size: 20px;
}

.stepCount {
  width: 32px;
  height: 32px;
}

.disabled {
  .stepCount {
    background-color: $medikit-neutral3;
  }

  .stepText {
    color: $medikit-neutral4;
  }
}

.separator {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
}