.btn-lg {
  min-width: 190px; }

button {
  outline: none; }

.primary-nav-category {
  display: inline-block; }
  .primary-nav-category .nav-link.active {
    border-bottom: 2px solid #e62e43; }

.account-nav-item.active {
  border-left: 3px solid #d90432; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal[aria-hidden=true] {
  display: none; }

.modal[aria-hidden=false] {
  display: block; }

.modal-overlay.hidden {
  display: none; }

.modal-dialog {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 540px; } }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 720px; } }

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1140px; } }

/***
 * Specific styling for Tabs for the provider portal.
 */
.nav.pp-nav-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .nav.pp-nav-tabs .nav-item {
    width: 225px;
    text-align: center; }
    .nav.pp-nav-tabs .nav-item:hover {
      cursor: pointer; }
  .nav.pp-nav-tabs .nav-item .nav-link {
    background-color: #4b5468;
    color: #ffffff; }
  .nav.pp-nav-tabs li.nav-item:first-child {
    margin-top: 8px; }
  .nav.pp-nav-tabs .nav-item.show .nav-link,
  .nav.pp-nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: bold; }
  .nav.pp-nav-tabs .nav-link {
    display: block;
    padding: 1rem 1rem; }

.pp-tab-content {
  background-color: #ffffff;
  padding: 30px 40px 30px 40px; }
  .pp-tab-content .tab-content-pane {
    margin-top: 0; }

.table thead {
  background-color: #4c5569;
  color: #ffffff; }
  .table thead tr {
    height: 72px; }
    .table thead tr th {
      vertical-align: middle;
      font-weight: normal; }

.table.lifted-table {
  margin-top: -72px;
  table-layout: fixed; }

.table td {
  vertical-align: middle; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafbfc; }

.tooltip-inner {
  background-color: #111826;
  border: 2px solid #111826; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #111826; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #111826; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #111826; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #111826; }

.pp-main {
  background-color: #e6e9f0;
  min-height: calc(100vh - 72px - 72px); }
  .pp-main.with-spinner {
    min-height: calc(100vh - 72px); }

.pp-search-criteria-wrapper {
  padding-top: 10px; }

.search-btn-row .search-btn-wrapper {
  padding-top: 32px; }
  .search-btn-row .search-btn-wrapper .input-group {
    text-align: center; }

.pagination-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 140px; }

.footer-wrapper {
  padding-top: 0px;
  width: 100%;
  line-height: 72px;
  height: 72px; }

/***
 Removing top-margin from headings to prevent collapsing margins
 from messing around with page layout
 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

p {
  margin-top: 8px; }

.pointer {
  cursor: pointer; }

.simple-link {
  cursor: pointer;
  color: #596dd4;
  text-decoration: none; }
  .simple-link:hover {
    text-decoration: underline; }

.simple-link-reverse {
  cursor: pointer;
  color: #596dd4;
  text-decoration: underline; }
  .simple-link-reverse:hover {
    text-decoration: none; }

.error-message-link {
  color: #d90432; }

.label {
  margin-bottom: 8px;
  font-size: 16px;
  display: block;
  width: 100%; }

.form-error {
  color: #e62e43; }

input {
  outline: none; }

input[type=text].error,
input[type=password].error {
  border-color: #e62e43; }

.input-group .spinner {
  right: 12px;
  top: 48px; }

.input-group {
  margin-bottom: 12px; }

.btn-lg {
  min-width: 190px; }

button {
  outline: none; }

.alert-info::before {
  background-color: #2e92e9; }

.alert-icon.alert-icon-info {
  color: #2e92e9; }

body {
  color: #1d1d1d; }
