@import '~styles/variables';

.remittancesWrapper {
  padding-top: 40px;
}

.middle {
  padding-top: 40px;
  height: 180px;
}

.bottomHalf {
  background-color: $medikit-neutral2;
}

.resultsWrapper {
  position: relative;
}

