@import '~styles/variables';

.sidebarWrapper {
    height: 100%;
    display: flex;
}

.copyWrapper {
    padding-top: 44px;
    padding-left: 63px;
    padding-right: 50px;

    background-color: $medikit-neutral1;

    h4 {
        letter-spacing: -0.22px;
        line-height: 32px;

        font-size: 20px;
    }

    ul {
        margin-left: -34px;
        margin-top: 16px;
        font-size: 16px;
        line-height: 28px;
    }
}

.mobileCopy {
    padding-top: 35px;
}

.image {
    img {
        height: 464px;
        min-width: 260px;
    }
    background-color: $medikit-neutral1;
    padding: 0;
}