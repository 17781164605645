@import '~styles/variables';

.iconWrapper {
  color: $medikit-black;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
  margin-top: -3px;
}

.chevronWrapper {
  color: #000000;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 3px;
}

.userWrapper {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
}

.userMenu {
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 500;

  ul {
    border: 1px solid $medikit-neutral4;
    width: 200px;
    background-color: $medikit-white;

    li {
      padding: 1px 10px 5px 10px;
      border-bottom: 1px solid $medikit-neutral4;

      a {
        color: $medikit-black;
        display: block;
      }
    }

    li:first-child {
      padding-top: 9px;
    }
  }
}