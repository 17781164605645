@import '~styles/variables';

.contentWrapper {
  padding-top: 150px;
  text-align: center;

  h2 {
    padding-top: 32px;
  }
}

.infoIcon {
  height: 82px;
  width: 82px;
  color: $medikit-black;
}

.button {
  margin-top: 50px;
  width: 305px;
}
