@import '~styles/variables';

.header {
  font-family: "DIN";

  font-size: 28px;
  letter-spacing: -0.88px;
  line-height: 48px;
  padding-left: 0;
  margin-bottom: 32px;

  .username {
    font-weight: 500;
  }
}

.container {
  padding: 60px 12px;
}