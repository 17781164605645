.button {
  margin-top: 10px;
  height: 48px;
  margin-left: 10px;
}

.button2 {
  margin-top: 10px;
  height: 48px;
  margin-left: 15px;
}

.username {
  padding-top: 25px;
  margin-bottom: 0px;
}

.inputHelper {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  text-align: left;
  z-index: 2;
}





