@import '~styles/variables';

.titleWrapper {
  background-color: $medikit-neutral1;
  padding: 24px 0px;
}

.contentWrapper {
  padding-top: 32px;
  padding-bottom: 100px;
}

.content {
  background-color: $medikit-white;
}

.fieldsWrapper {
  background-color: $medikit-white;
  padding: 40px;
}

.fieldsErrorWrapper {
  color: $medikit-primary-dark;
  padding: 0px 40px 20px 40px;
  margin-top: -25px;
}

.copyWrapper {
  padding: 80px 50px 0px 50px;
  background-color: $medikit-neutral1;
}

.copyErrorWrapper {
  background-color: $medikit-neutral1;
}

.buttonWrapper {
  background-color: $medikit-white;
  padding: 0px 40px 40px 40px;
}