@import '~styles/variables';

.claimsWrapper {
  padding-top: 40px;
}

.lineCount {
  padding-top: 20px;
  padding-bottom: 20px;
}

.lineItemsHeader {
  width: 1140px;
}

.lineItemsHeaderWrapper {
  background-color: $medikit-neutral3;
}

.lineItemsHeaderWrapperStuck {
  background-color: $medikit-neutral3;
  position: fixed;
  top: 0;
  width: 100%;
}

.stuck {
  padding-bottom: 50px;
}

.bottomHalf {
  background-color: $medikit-neutral2;
}

.bottomHalfStuck {
  background-color: $medikit-neutral2;
  padding-top: 74px;
}