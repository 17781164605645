.btn-lg {
  min-width: 190px; }

button {
  outline: none; }

.primary-nav-category {
  display: inline-block; }
  .primary-nav-category .nav-link.active {
    border-bottom: 2px solid #e62e43; }

.account-nav-item.active {
  border-left: 3px solid #d90432; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal[aria-hidden=true] {
  display: none; }

.modal[aria-hidden=false] {
  display: block; }

.modal-overlay.hidden {
  display: none; }

.modal-dialog {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 540px; } }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 720px; } }

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1140px; } }

/***
 * Specific styling for Tabs for the provider portal.
 */
.nav.pp-nav-tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .nav.pp-nav-tabs .nav-item {
    width: 225px;
    text-align: center; }
    .nav.pp-nav-tabs .nav-item:hover {
      cursor: pointer; }
  .nav.pp-nav-tabs .nav-item .nav-link {
    background-color: #4b5468;
    color: #ffffff; }
  .nav.pp-nav-tabs li.nav-item:first-child {
    margin-top: 8px; }
  .nav.pp-nav-tabs .nav-item.show .nav-link,
  .nav.pp-nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: bold; }
  .nav.pp-nav-tabs .nav-link {
    display: block;
    padding: 1rem 1rem; }

.pp-tab-content {
  background-color: #ffffff;
  padding: 30px 40px 30px 40px; }
  .pp-tab-content .tab-content-pane {
    margin-top: 0; }

.table thead {
  background-color: #4c5569;
  color: #ffffff; }
  .table thead tr {
    height: 72px; }
    .table thead tr th {
      vertical-align: middle;
      font-weight: normal; }

.table.lifted-table {
  margin-top: -72px;
  table-layout: fixed; }

.table td {
  vertical-align: middle; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafbfc; }

.tooltip-inner {
  background-color: #111826;
  border: 2px solid #111826; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #111826; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #111826; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #111826; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #111826; }

.pp-main {
  background-color: #e6e9f0;
  min-height: calc(100vh - 72px - 72px); }
  .pp-main.with-spinner {
    min-height: calc(100vh - 72px); }

.pp-search-criteria-wrapper {
  padding-top: 10px; }

.search-btn-row .search-btn-wrapper {
  padding-top: 32px; }
  .search-btn-row .search-btn-wrapper .input-group {
    text-align: center; }

.pagination-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 140px; }

.footer-wrapper {
  padding-top: 0px;
  width: 100%;
  line-height: 72px;
  height: 72px; }

/***
 Removing top-margin from headings to prevent collapsing margins
 from messing around with page layout
 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

p {
  margin-top: 8px; }

.pointer {
  cursor: pointer; }

.simple-link {
  cursor: pointer;
  color: #596dd4;
  text-decoration: none; }
  .simple-link:hover {
    text-decoration: underline; }

.simple-link-reverse {
  cursor: pointer;
  color: #596dd4;
  text-decoration: underline; }
  .simple-link-reverse:hover {
    text-decoration: none; }

.error-message-link {
  color: #d90432; }

.label {
  margin-bottom: 8px;
  font-size: 16px;
  display: block;
  width: 100%; }

.form-error {
  color: #e62e43; }

input {
  outline: none; }

input[type=text].error,
input[type=password].error {
  border-color: #e62e43; }

.input-group .spinner {
  right: 12px;
  top: 48px; }

.input-group {
  margin-bottom: 12px; }

.btn-lg {
  min-width: 190px; }

button {
  outline: none; }

.alert-info::before {
  background-color: #2e92e9; }

.alert-icon.alert-icon-info {
  color: #2e92e9; }

body {
  color: #1d1d1d; }

.WelcomeComponent_welcome__9t9_r {
  text-align: center; }
  .WelcomeComponent_welcome__9t9_r h2 {
    color: #000000;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: 48px; }
  .WelcomeComponent_welcome__9t9_r .WelcomeComponent_iconWrapper__1_KVw {
    height: 88px;
    width: 88px;
    margin-top: 143px;
    margin-bottom: 53px;
    margin-left: auto;
    margin-right: auto; }
  .WelcomeComponent_welcome__9t9_r .WelcomeComponent_welcomeInfo__2eh-W {
    line-height: 24px;
    max-width: 392px;
    margin: 0 auto;
    font-size: 16px; }
  .WelcomeComponent_welcome__9t9_r .WelcomeComponent_registrationProcessNote__2rKcA {
    width: 502px;
    height: 48px;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 80px;
    text-align: center;
    color: #585D67; }

.RegisterDoctorModalComponent_modalClose__OQmW7 {
  margin-top: 34px; }

.RegisterDoctorModalComponent_content__31hP0 {
  color: #000000;
  text-align: left; }

.RegisterDoctorModalComponent_DrRegisterModal__3cc-z {
  width: 859px;
  height: 690px; }

.RegisterDoctorModalComponent_noteMsg__P3k8R {
  color: #585D67; }

.RegisterDoctorModalComponent_modalCloseBtn__2Heoi {
  top: 24px; }

.RegisterDoctorModalComponent_downloadFormBtn__2GZhf {
  width: 255px;
  height: 50px; }

.RegisterDoctorModalComponent_downloadFormNote__1z2h9 {
  font-size: 14px;
  line-height: 24px;
  color: #585D67; }

.RegisterDoctorModalComponent_selfServiceMailId__2V0cr {
  color: #596dd4;
  text-decoration: underline;
  display: inline; }

.DashboardComponent_header__2jcBH {
  font-family: "DIN";
  font-size: 28px;
  letter-spacing: -0.88px;
  line-height: 48px;
  padding-left: 0;
  margin-bottom: 32px; }
  .DashboardComponent_header__2jcBH .DashboardComponent_username__1Zdd8 {
    font-weight: 500; }

.DashboardComponent_container__32slR {
  padding: 60px 12px; }

.DashboardTile_tileWrapper__1QSX1 {
  padding: 0; }

.DashboardTile_tileWrapper__1QSX1:first-of-type {
  padding-right: 24px; }

.DashboardTile_tileWrapper__1QSX1:last-of-type {
  padding-left: 24px; }

.DashboardTile_tile__39LZi {
  background-color: #ffffff;
  margin-top: 24px;
  padding: 55px 0px; }

.DashboardTile_hiddenTile__IvUSH {
  margin-top: 24px;
  padding: 55px 0px; }

.DashboardTile_iconWrapper__1eiT6 {
  height: 64px;
  width: 64px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px; }

.DashboardTile_title__2o3KI {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center; }

.DashboardTile_link__3pNvT {
  text-decoration: none;
  color: #1d1d1d; }

.AlertsComponent_alerts__1j_FI {
  background-color: #ffffff;
  border-left: #009e8c solid 4px;
  padding: 16px 32px 16px 40px;
  margin-bottom: 8px; }
  .AlertsComponent_alerts__1j_FI h4 {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.75px;
    line-height: 24px; }
  .AlertsComponent_alerts__1j_FI .AlertsComponent_navLink__uq0Eq {
    font-size: 16px;
    line-height: 24px;
    text-align: right; }
    .AlertsComponent_alerts__1j_FI .AlertsComponent_navLink__uq0Eq a {
      vertical-align: middle; }

.pp-provider-select .input-group input[type=text]:disabled {
  background-color: #ffffff; }

.pp-provider-select .input-group .icon.clear-btn {
  left: auto;
  right: .7rem;
  bottom: 1.2rem;
  width: 10px;
  height: 10px; }
  .pp-provider-select .input-group .icon.clear-btn:hover {
    cursor: pointer; }

.pp-provider-select .selected-provider-wrapper {
  position: absolute;
  left: 2.5rem;
  bottom: 0.7rem; }

.pp-provider-select .selected-multi-provider-wrapper {
  position: absolute;
  left: 2.5rem;
  bottom: 0.7rem;
  font-size: 14px;
  background-color: #f2f4f7;
  padding-left: 5px;
  padding-right: 35px;
  border-radius: 1px; }
  .pp-provider-select .selected-multi-provider-wrapper .icon.clear-btn {
    right: .5rem;
    bottom: .5rem;
    width: 8px;
    height: 8px; }

.pp-provider-list-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #e6e9f0;
  position: absolute;
  background: #fff;
  z-index: 9;
  width: 100%;
  max-height: 280px;
  overflow: auto; }

.pp-listed-provider {
  cursor: pointer;
  border-bottom: 1px solid #e6e9f0;
  padding: 10px;
  margin-top: 0;
  display: -webkit-flex;
  display: flex; }
  .pp-listed-provider .r-col {
    padding-left: 20px; }
  .pp-listed-provider:hover {
    background-color: #f2f4f7; }

.pp-provider-multilist-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  width: 100%;
  max-height: 400px;
  overflow: auto; }
  .pp-provider-multilist-wrapper .pp-listed-provider-wrapper {
    border-top: 2px solid #e6e9f0;
    border-left: 2px solid #e6e9f0;
    border-right: 2px solid #e6e9f0; }
    .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(even) {
      border-left: 1px solid #e6e9f0; }
    .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(odd) {
      border-right: 1px solid #e6e9f0; }
    .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:first-child, .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(2) {
      border-top: 2px solid #d8dbe3; }
    .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:last-child, .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-last-child(2) {
      border-bottom: 2px solid #d8dbe3; }
    @media (max-width: 991px) {
      .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(2) {
        border-top: 2px solid #e6e9f0; }
      .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(even) {
        border-left: 2px solid #e6e9f0; }
      .pp-provider-multilist-wrapper .pp-listed-provider-wrapper:nth-child(odd) {
        border-right: 2px solid #e6e9f0; } }
  .pp-provider-multilist-wrapper .pp-listed-provider {
    cursor: default;
    border-bottom: none; }
    .pp-provider-multilist-wrapper .pp-listed-provider .input-group {
      width: auto;
      width: initial;
      padding-top: 3px; }
      .pp-provider-multilist-wrapper .pp-listed-provider .input-group .input-checkbox {
        padding-right: 12px; }

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 0.75rem;
  border: 1px solid #d8dbe3;
  position: absolute;
  background-color: #ffffff;
  z-index: 500;
}

.DayPicker-wrapper {
  position: relative;

  -webkit-flex-direction: row;

          flex-direction: row;
  padding-bottom: 0.4em;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 2em;
  right: 1em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton:focus {
  outline: none;
}

.DayPicker-NavButton--prev {
  left: 1rem;
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center;
}

.DayPicker-Caption .select {
  position: relative;
  display: inline-block;
}

.DayPicker-Caption .select:first-child {
  margin-right: 10px;
}

.DayPicker-Caption .select:last-child {
  margin-left: 10px;
}

.DayPicker-Caption select {
  width: auto;
  width: initial;
  display: inline-block;
  padding: .55rem 2rem .55rem .5rem;
}



.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.4em 1em;
  /*border-radius: 50%;*/
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #111826;
  background-color: #e6e9f0;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  /*cursor: default;*/
}

.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #312669;
  color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #4e3c9e;
  color: #F0F8FF;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
  background-color: #4e3c9e;
  color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
  width: 100%;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DateRangePickerComponent_rangeWrapper__2t0Lr {
  margin-bottom: 10px; }

.SearchCriteriaComponent_searchCriteriaWrapper__275Hw {
  padding-top: 30px; }

.SearchCriteriaComponent_searchCriteriaInner__3NTBh {
  padding: 40px 40px 50px 40px;
  background-color: #ffffff; }

.SearchCriteriaComponent_dateSearchRow__26phM {
  padding-top: 1rem; }

.SearchCriteriaComponent_helpText__1JBNz {
  font-size: 14px; }

.ClaimsContainer_claimsWrapper__2mVl8 {
  padding-top: 40px; }

.ClaimsContainer_middle__DRNRR {
  padding-top: 50px;
  padding-bottom: 20px; }

.ClaimsContainer_bottomHalf__2amyO {
  background-color: #f2f4f7; }

.ClaimsContainer_resultsWrapper__2WmHE {
  position: relative; }

.AlertComponent_alertWrapper__2EQ1j {
  padding-bottom: 20px; }

.AlertComponent_alertTitle__6kMKW {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 16px; }

.AlertComponent_closeIcon__1IJTW {
  cursor: pointer; }

.OverlaySpinner_centerSpinner__2YoEG {
  text-align: center; }

.OverlaySpinner_overlay__3Hry3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 500;
  padding-top: 30px; }

.OverlaySpinner_clearOverlay__2PqNL {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  padding-top: 30px; }

.OverlaySpinner_darkerSpinner__2QFPl {
  color: #8c92a1; }

.PaginationLinksComponent_linksWrapper__2GjhN {
  display: inline-block; }

.PaginationLinksComponent_pagination__3YQaL {
  text-align: center; }

.PaginationLinksComponent_paginationList__1W8fU {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .PaginationLinksComponent_paginationList__1W8fU li {
    display: inline;
    padding-left: 5px;
    padding-right: 5px; }

.PaginationLinksComponent_isCurrent__x4rrJ {
  color: black;
  text-decoration: none;
  font-weight: bold;
  cursor: default; }

button.PaginationLinksComponent_navButton__3U4mN:first-child {
  margin-right: 10px; }

button.PaginationLinksComponent_navButton__3U4mN:last-child {
  margin-left: 10px; }

button.PaginationLinksComponent_navButton__3U4mN:focus {
  outline: 0; }

.SearchResultsItemComponent_topRow__1lRJB {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 40px;
  margin-top: 20px; }

.SearchResultsItemComponent_topRow__1lRJB:first-child {
  margin-top: 0px; }

.SearchResultsItemComponent_dateIcon__3-zcy {
  height: 12px;
  width: 12px;
  margin-right: 16px;
  margin-bottom: 5px; }
  .SearchResultsItemComponent_dateIcon__3-zcy path {
    stroke-width: 1; }

.SearchResultsItemComponent_titleRow__mKZvp {
  background-color: #ffffff; }

.SearchResultsItemComponent_detailRow__1lTaK {
  background-color: #ffffff;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px; }

.SearchResultsItemComponent_pendingIcon__qzlFv {
  margin-left: -7px;
  margin-bottom: 4px; }

.SearchResultsItemComponent_subDetailRow__1fuwB {
  background-color: #ffffff;
  padding-bottom: 20px; }

.SearchResultsItemComponent_eclipseRow__3v8P5 {
  background-color: #ffffff;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 20px; }

.SearchResultsItemComponent_benefitRow__14uAQ {
  background-color: #fafbfc;
  padding-bottom: 20px;
  padding-top: 20px;
  -webkit-align-items: center;
          align-items: center; }
  .SearchResultsItemComponent_benefitRow__14uAQ .SearchResultsItemComponent_left__Ocspb {
    -webkit-flex: 0 0 18%;
            flex: 0 0 18%;
    max-width: 18%; }
  .SearchResultsItemComponent_benefitRow__14uAQ .SearchResultsItemComponent_right__3neQF {
    text-align: right;
    -webkit-flex: 0 0 32%;
            flex: 0 0 32%;
    max-width: 32%; }
  .SearchResultsItemComponent_benefitRow__14uAQ .SearchResultsItemComponent_tba__QIpHR {
    -webkit-flex: 0 0 32%;
            flex: 0 0 32%;
    max-width: 32%; }

.SearchResultsItemComponent_benefitTitle__HpCg- {
  font-weight: 500;
  font-size: 18px; }

.SearchResultsItemComponent_detailsBtn__3nxbD {
  padding: 3px 20px 1px; }

.SearchResultsItemComponent_largeNumbers__2YmtT {
  font-size: 38px;
  position: relative;
  top: 10px;
  padding-right: 16px;
  font-weight: 500; }

.SearchResultsItemComponent_dollarSign__1GJ8s {
  font-size: 20px;
  position: relative;
  top: -5px; }

.SearchResultsItemComponent_statusLink__20Os8 {
  color: #009e8c;
  padding-right: 10px;
  cursor: pointer; }
  .SearchResultsItemComponent_statusLink__20Os8:hover {
    text-decoration: underline; }

.SearchResultsItemComponent_lightGrey__1qUcy {
  opacity: 0.7; }

.SearchResultsItemComponent_lightBold__24C0L {
  font-weight: 500; }

.SearchResultsComponent_emptyRowStyles__1Jh15 {
  height: 80px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.ClaimsDetailContainer_claimsWrapper__3Lul2 {
  padding-top: 40px; }

.ClaimsDetailContainer_lineCount__3hh32 {
  padding-top: 20px;
  padding-bottom: 20px; }

.ClaimsDetailContainer_lineItemsHeader__t-Irk {
  width: 1140px; }

.ClaimsDetailContainer_lineItemsHeaderWrapper__26epB {
  background-color: #e6e9f0; }

.ClaimsDetailContainer_lineItemsHeaderWrapperStuck__N7t87 {
  background-color: #e6e9f0;
  position: fixed;
  top: 0;
  width: 100%; }

.ClaimsDetailContainer_stuck__3snTq {
  padding-bottom: 50px; }

.ClaimsDetailContainer_bottomHalf__2B4A6 {
  background-color: #f2f4f7; }

.ClaimsDetailContainer_bottomHalfStuck__2HTOI {
  background-color: #f2f4f7;
  padding-top: 74px; }

.DetailHeaderComponent_sectionWrapper__2V3or {
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 30px; }

.DetailHeaderComponent_detailRow__1o6Vw {
  padding-top: 5px;
  padding-bottom: 10px; }

.DetailHeaderComponent_eclipseRow__2LxZS {
  padding-top: 20px; }

.DetailLineItemsHeaderComponent_tableHeader__3eBtP {
  color: #ffffff;
  font-size: 13px;
  line-height: 17px;
  font-weight: 100;
  border-spacing: 0;
  width: 100%; }
  .DetailLineItemsHeaderComponent_tableHeader__3eBtP td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px; }

.DetailLineItemsHeaderComponent_datesTopCell__1bZRN {
  background-color: #312669;
  max-width: 240px;
  width: 240px; }

.DetailLineItemsHeaderComponent_datesBottomCell__gm10v {
  background-color: #4e3c9e;
  max-width: 70px;
  width: 70px; }

.DetailLineItemsHeaderComponent_datesAssessCell__IeLFH {
  background-color: #4e3c9e;
  max-width: 100px;
  width: 100px; }

.DetailLineItemsHeaderComponent_providerTopCell__2aL4M {
  background-color: #00666e;
  max-width: 208px;
  width: 208px; }

.DetailLineItemsHeaderComponent_providerBottomCell__3G_DA {
  background-color: #00838c; }

.DetailLineItemsHeaderComponent_statusCell__OZuLh {
  background-color: #111826;
  max-width: 119px;
  width: 119px; }

.DetailLineItemsHeaderComponent_chequeCell__2OC_4 {
  background-color: #4b5468;
  max-width: 112px;
  width: 112px; }

.DetailLineItemsHeaderComponent_serviceCell__1vwnm {
  background-color: #4b5468;
  max-width: 65px;
  width: 65px; }

.DetailLineItemsHeaderComponent_mbsItemNoCell__3XdpO {
  background-color: #4b5468;
  max-width: 60px;
  width: 60px; }

.DetailLineItemsHeaderComponent_mbsFeeCell__2RTW5 {
  background-color: #4b5468;
  max-width: 65px;
  width: 65px; }

.DetailLineItemsHeaderComponent_mplCell__3mRsC {
  background-color: #4b5468;
  max-width: 84px;
  width: 84px; }

.DetailLineItemsHeaderComponent_benefitCell__3y989 {
  background-color: #4b5468;
  max-width: 84px;
  width: 84px; }

.DetailLineItemsHeaderComponent_itemCell__1Xi0Z {
  background-color: #4b5468; }

.DetailLineItemsResultsListComponent_linesTable__1zIRI {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  line-height: 17px;
  border-spacing: 0;
  width: 100%; }
  .DetailLineItemsResultsListComponent_linesTable__1zIRI td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px; }

.DetailLineItemsItemComponent_datesBottomCell__1k-OY {
  max-width: 70px;
  width: 70px; }

.DetailLineItemsItemComponent_datesAssessCell__2X-J- {
  max-width: 100px;
  width: 100px; }

.DetailLineItemsItemComponent_providerTopCell__3r64x {
  max-width: 208px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.DetailLineItemsItemComponent_providerName__eSATO {
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.DetailLineItemsItemComponent_chequeCell__2OVBC {
  max-width: 112px;
  width: 112px; }

.DetailLineItemsItemComponent_serviceCell__22Pxc {
  max-width: 65px;
  width: 65px; }

.DetailLineItemsItemComponent_mbsItemNoCell__2ZJPV {
  max-width: 60px;
  width: 60px; }

.DetailLineItemsItemComponent_mbsFeeCell__3BS6I {
  max-width: 65px;
  width: 65px; }

.DetailLineItemsItemComponent_mplCell__SIznc {
  max-width: 84px;
  width: 84px; }

.DetailLineItemsItemComponent_benefitCell__2vPJs {
  max-width: 84px;
  width: 84px; }

.DetailLineItemsItemComponent_statusCell__1UNeS {
  max-width: 119px;
  width: 119px; }

.DetailLineItemsItemComponent_statusWrapper__23kJI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.DetailLineItemsItemComponent_statusChevron__iq1wf {
  padding-left: 5px;
  position: relative;
  top: 3px;
  cursor: pointer; }

.DetailLineItemsItemComponent_statusChevronOpen__3i31J {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer; }

.TooltipIcon_tooltipIconClass__3Z0u5 {
  height: 15px;
  width: 15px;
  margin-left: 7px; }

.SearchCriteriaComponent_navLinkWide__gUYiv {
  width: 240px; }

.SearchCriteriaComponent_dateSearchRowStyle__2Tgx7 {
  paddingTop: '2rem'; }

.SearchResultsComponent_emptyRowStyles__2wJNL {
  height: 80px;
  width: 100%;
  text-align: center; }

.SearchResultsComponent_resultsWrapper__3d36f {
  background-color: #ffffff;
  margin-bottom: 0px; }

.SearchResultsComponent_th1__2DhIU {
  width: 130px;
  min-width: 130px; }

.SearchResultsComponent_th2__3UVJq {
  width: 157px;
  min-width: 157px; }

.SearchResultsComponent_th3__2_iTW {
  width: auto;
  min-width: 320px; }

.SearchResultsComponent_th4__ceZF8 {
  width: 160px;
  min-width: 160px; }

.SearchResultsComponent_th5__1DfU_ {
  width: 150px;
  min-width: 150px; }

.RemittancesContainer_remittancesWrapper__2skbC {
  padding-top: 40px; }

.RemittancesContainer_middle__1r8sz {
  padding-top: 40px;
  height: 180px; }

.RemittancesContainer_bottomHalf__2lHs7 {
  background-color: #f2f4f7; }

.RemittancesContainer_resultsWrapper__7hQTX {
  position: relative; }

.RegistrationContainer_stepsWrapper__2ZXp7 {
  background-color: #ffffff;
  padding-bottom: 20px; }

.RegistrationContainer_alertWrapper__196nW {
  padding-top: 30px; }

.TermsAndConditionsContainer_alert__36IT3 span {
  font-size: 21px;
  font-weight: 500; }

.TermsAndConditionsContainer_contentWrapper__2wYk2 {
  padding-top: 48px;
  padding-bottom: 30px; }

.TermsAndConditionsContainer_content__3FiJ6 {
  background-color: #fafbfc;
  padding: 32px 40px; }

.TermsAndConditionsContainer_terms__3QPBP {
  border: 2px solid #e6e9f0;
  overflow: scroll;
  padding: 8px;
  max-height: calc(100vh - 430px); }

.TermsAndConditionsContainer_checkbox-agree__3lTbr {
  float: left;
  width: auto;
  width: initial;
  margin-top: 11px;
  margin-bottom: 11px; }

.TermsAndConditionsContainer_checkbox-wrapper__WwFIr {
  margin-bottom: 0 !important; }

.TermsAndConditionsContainer_footer__Dtweq {
  overflow: overlay;
  margin-top: 30px; }
  .TermsAndConditionsContainer_footer__Dtweq button {
    float: right; }

/*
    This handles setting the opacity and cursor of the checkbox for the
    terms and conditions agreement. It does not set the cursor or opacity
    of the hyperlinks to the various policies within the checkbox's label.

    !important must be used as the specificity of this rule is superceded
    by that of some of Medikit's rules.
*/
.input-checkbox.TermsAndConditionsContainer_disabled__2Bhdl span {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: initial !important; }

.TermsAndConditions_terms-and-conditions__3UE85 h1 {
  font-size: 1.5em; }

.TermsAndConditions_terms-and-conditions__3UE85 h2 {
  font-size: 1.3em; }

.TermsAndConditions_terms-and-conditions__3UE85 ol li span {
  position: relative;
  left: 30px; }

.Step1Container_step1Wrapper__1JEiN {
  padding-top: 40px; }

.Step1Container_roleRow__28FzU {
  margin-top: 30px;
  padding-bottom: 100px; }

.Step1Container_introText__19HrY {
  background-color: #ffffff;
  padding: 20px 32px;
  margin-bottom: 30px; }

.SelectRoleComponent_header__3Akf- {
  height: 150px;
  background-color: #4054b8;
  padding: 32px; }

.SelectRoleComponent_iconWrapper__1F_xa {
  color: #ffffff;
  height: 48px;
  width: 48px; }

.SelectRoleComponent_title__14nam {
  margin-top: 15px;
  font-size: 21px;
  color: #ffffff; }

.SelectRoleComponent_body__28Igf {
  height: 200px;
  background-color: #ffffff;
  padding: 32px; }

.SelectRoleComponent_footer__3zv4O {
  background-color: #ffffff;
  padding: 32px;
  text-align: center; }
  .SelectRoleComponent_footer__3zv4O button {
    width: 100%; }

.Step2Container_titleWrapper__FNK0J {
  background-color: #fafbfc;
  padding: 24px 0px; }

.Step2Container_contentWrapper__1ZSqT {
  padding-top: 32px;
  padding-bottom: 100px; }

.Step2Container_content__3_ck- {
  background-color: #ffffff; }

.Step2Container_fieldsWrapper__35_ZL {
  background-color: #ffffff;
  padding: 40px; }

.Step2Container_fieldsErrorWrapper__Rzc4A {
  color: #d90432;
  padding: 0px 40px 20px 40px;
  margin-top: -25px; }

.Step2Container_copyWrapper__IH0Mt {
  padding: 80px 50px 0px 50px;
  background-color: #fafbfc; }

.Step2Container_copyErrorWrapper__242jc {
  background-color: #fafbfc; }

.Step2Container_buttonWrapper__1lOPJ {
  background-color: #ffffff;
  padding: 0px 40px 40px 40px; }

.Step2DVSInfoComponent_copyPadded__34FU3 {
  padding-top: 20px; }

.Step2FormContainer_identityWrapper__3rFH5 {
  padding-top: 80px; }

.Step2FormContainer_inputGroupSmallTwoThirds__2znVb {
  width: calc(66% - 3px); }

.Step2FormContainer_inputGroupSmallOneThird__19qOc {
  margin-left: 6px;
  width: calc(33% - 3px); }

.DateInputComponent_dateError__39Jib {
  padding-top: 10px; }

.Step3Container_titleWrapper__1W3Ww {
  background-color: #ffffff;
  padding: 40px 40px 0px 40px; }

.Step3Container_copyWrapper__1K-Dg {
  padding: 25px 40px 0px 40px;
  background-color: #fafbfc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.Step3Container_radioWrapper__YwD20 {
  background-color: #ffffff;
  padding: 0px 40px 0px 40px; }
  .Step3Container_radioWrapper__YwD20 .Step3Container_radioLabel__1R8lg {
    margin-bottom: 10px; }

.Step3Container_radioHelp__4bo5C {
  padding-left: 30px; }

.Step3Container_radioCopyWrapper__bBwHr {
  padding: 0px 40px 0px 40px;
  background-color: #fafbfc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.Step3Container_createAcctWrapper__XIgEs {
  background-color: #ffffff;
  padding: 10px 40px 0px 40px; }

.Step3Container_fieldsWrapper__3Bw6m {
  background-color: #ffffff;
  padding: 0px 40px 0px 40px; }

.Step3Container_termsConditions__15UMo {
  padding-top: 10px;
  padding-bottom: 40px; }

.Step3Container_contentWrapper__1ZKUI {
  padding-top: 32px;
  padding-bottom: 100px; }

.PasswordInputComponent_explanation__39yKy {
  margin-bottom: 15px; }

.PasswordInputComponent_inputHelper__3xqdx {
  padding-top: 10px; }

.Step3UsernameContainer_inputHelper__3quid {
  padding-top: 10px; }

.EmailComponent_inputHelper__3bLRx {
  padding-top: 10px; }

.MobileNoInputContainer_inputHelper__3T0Sc {
  padding-top: 10px; }

.Step4Container_titleWrapper__5eZy0 {
  background-color: #ffffff;
  padding: 40px 40px 0px 40px; }

.Step4Container_copyWrapper__3mkgI {
  padding: 0px 40px 0px 40px;
  background-color: #fafbfc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.Step4Container_fieldsWrapper__23Lw8 {
  background-color: #ffffff;
  padding: 0px 40px 0px 40px;
  z-index: 2; }

.Step4Container_buttonWrapper__GPKdk {
  background-color: #ffffff;
  padding: 10px 40px 40px 40px;
  z-index: 1; }

.Step4Container_contentWrapper__i4pVk {
  padding-top: 32px;
  padding-bottom: 100px; }

.Step4CodeContainer_inputHelper__18MKV {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  z-index: 2; }

.ResendVerificationCodeLink_messageResend__Ql-sO {
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  margin-left: 0px; }

.VerifyEmailComponent_headerWrapper__3GpXp {
  background-color: #fafbfc;
  padding: 24px 0px; }

.VerifyEmailComponent_titleWrapper__3AjEi {
  background-color: #ffffff;
  padding: 40px 40px 0px 40px; }

.VerifyEmailComponent_copyWrapper__2XVW7 {
  padding: 25px 40px 0px 40px;
  background-color: #fafbfc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.VerifyEmailComponent_infoWrapper__1DWPC {
  background-color: #ffffff;
  padding: 0px 40px 0px 40px; }

.VerifyEmailComponent_infoCopyWrapper__1-jGf {
  padding: 0px 40px 0px 40px;
  background-color: #fafbfc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.VerifyEmailComponent_buttonWrapper__2ZQcG {
  background-color: #ffffff;
  padding: 60px 40px 40px 40px;
  z-index: 1; }

.VerifyEmailComponent_nextButton__3y9o8 {
  width: 295px; }

.VerifyEmailComponent_contentWrapper__-Kc1i {
  padding-top: 32px;
  padding-bottom: 100px; }

.VerifyEmailComponent_closeButtonCell__1lRaE {
  text-align: right;
  padding-top: 4px; }

.VerifyEmailComponent_closeButton__2DQTZ {
  cursor: pointer; }

.StepsHeaderComponent_stepsContainer__2XJDV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center; }

.StepsHeaderComponent_step__3i5at {
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  font-size: 20px; }

.StepsHeaderComponent_stepCount__4ajfl {
  width: 32px;
  height: 32px; }

.StepsHeaderComponent_disabled__wcNdY .StepsHeaderComponent_stepCount__4ajfl {
  background-color: #e6e9f0; }

.StepsHeaderComponent_disabled__wcNdY .StepsHeaderComponent_stepText__3QZBl {
  color: #d8dbe3; }

.StepsHeaderComponent_separator__3aWjr {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px; }

.Step1Container_contentWrapper__2EgDd {
  padding-top: 80px;
  padding-bottom: 100px; }
  .Step1Container_contentWrapper__2EgDd hr {
    margin: 7px 0; }

.Step1Container_content__1pAzp {
  background-color: #ffffff; }

.Step1Container_colPadding__1AqZU {
  background-color: #ffffff;
  padding-right: 0px;
  padding-left: 0px; }

.Step1Container_loginWrapper__2BqYO {
  background-color: #ffffff;
  padding: 32px 40px 30px 40px; }

.Step1Container_username__2g4wb {
  padding-top: 25px;
  margin-bottom: 24px; }

.Step1Container_password__22rg3 {
  padding-top: 24px; }

.Step1Container_copyWrapper__1TibI {
  padding-top: 44px;
  padding-left: 63px;
  padding-right: 50px;
  background-color: #f2f4f7; }
  .Step1Container_copyWrapper__1TibI h4 {
    letter-spacing: -0.22px;
    line-height: 32px;
    height: 77px;
    font-size: 20px; }
  .Step1Container_copyWrapper__1TibI ul {
    margin-left: -34px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 28px; }

.Step1Container_image__1z1dV {
  padding: 0; }
  .Step1Container_image__1z1dV img {
    height: 100%; }

.Step1Container_link__1BFQu {
  margin-top: 24px;
  display: block;
  text-align: center; }

.Step1Container_button__1KAU- {
  margin-top: 20px;
  height: 48px; }

.Step1Container_inputHelper__3qP-o {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  z-index: 2; }

.Step1Container_message__1qjx7 {
  margin-left: 140px; }

.Step1Container_errorLink__3zhEJ {
  text-decoration: underline;
  color: #e62e43; }
  .Step1Container_errorLink__3zhEJ:hover {
    text-decoration: none; }

.Step2Container_mfa__1JScJ {
  margin-top: 32px; }

.LoginContainer_contentWrapper__3bYpI {
  padding-top: 80px;
  padding-bottom: 100px; }
  .LoginContainer_contentWrapper__3bYpI hr {
    margin: 7px 0; }

.LoginContainer_colPadding__2luW9 {
  background-color: #ffffff;
  padding-right: 0px;
  padding-left: 0px; }

.LoginContainer_sidebarWrapper__r05ij {
  padding-right: 0px;
  padding-left: 0px; }

.LoginContainer_messageWrapper__2DL2G {
  padding-left: 0px;
  padding-right: 0px; }

.PortalInfoSidebarComponent_sidebarWrapper__3Tnyu {
  height: 100%;
  display: -webkit-flex;
  display: flex; }

.PortalInfoSidebarComponent_copyWrapper__Ju9aR {
  padding-top: 44px;
  padding-left: 63px;
  padding-right: 50px;
  background-color: #fafbfc; }
  .PortalInfoSidebarComponent_copyWrapper__Ju9aR h4 {
    letter-spacing: -0.22px;
    line-height: 32px;
    font-size: 20px; }
  .PortalInfoSidebarComponent_copyWrapper__Ju9aR ul {
    margin-left: -34px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 28px; }

.PortalInfoSidebarComponent_mobileCopy__RPOtX {
  padding-top: 35px; }

.PortalInfoSidebarComponent_image__1jbAQ {
  background-color: #fafbfc;
  padding: 0; }
  .PortalInfoSidebarComponent_image__1jbAQ img {
    height: 464px;
    min-width: 260px; }

.ScheduledOutageComponent_column__34PiH {
  padding-left: 0px;
  padding-right: 0px; }

.ScheduledOutageComponent_alertWrapper__Hysir {
  padding-bottom: 20px; }

.ScheduledOutageComponent_alertTitle__1Ix6_ {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 16px; }

.ScheduledOutageComponent_alertColor__8sUii:before {
  background-color: #596dd4; }

.ScheduledOutageComponent_alertIconColor__3pbXr {
  color: #596dd4; }

.Step1Container_button__BngIH {
  margin-top: 20px;
  height: 48px; }

.PwdStep2Container_button__24q-6 {
  margin-top: 10px;
  height: 48px;
  margin-left: 10px; }

.PwdStep2Container_button2__S0tAo {
  margin-top: 10px;
  height: 48px;
  margin-left: 15px; }

.PwdStep2Container_username__2KFnP {
  padding-top: 25px;
  margin-bottom: 0px; }

.PwdStep2Container_inputHelper__3-2oe {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  text-align: left;
  z-index: 2; }

.PwdStep3Container_password__2CDQb {
  padding-top: 18px;
  margin-bottom: 24px; }

.PwdStep3Container_button__1CTd0 {
  margin-top: 0px;
  margin-bottom: 10px;
  height: 48px;
  margin-left: 10px; }

.PwdStep3Container_button2__2dOZa {
  margin-top: 0px;
  margin-bottom: 10px;
  height: 48px;
  margin-left: 15px; }

.PwdStep3Container_link__30LXn {
  margin-top: 16px;
  display: block;
  text-align: center;
  margin-bottom: 0px; }

.PwdStep3Container_code__2KiVx {
  margin-top: 24px;
  margin-bottom: 5px; }

.PwdStep3Container_inputHelper__1YVcC {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  text-align: left;
  z-index: 2; }

.UsernameStep2Container_titleSection__1XYM6 {
  text-align: center;
  margin-bottom: 20px; }

.UsernameStep2Container_button__3ZpZX {
  margin-top: 10px;
  height: 48px;
  margin-left: 10px; }

.UsernameStep2Container_button2__Pe3yU {
  margin-top: 10px;
  height: 48px;
  margin-left: 15px; }

.ForgottenContainer_contentWrapper__3NyxV {
  padding-top: 80px;
  padding-bottom: 100px; }

.ForgottenContainer_colPadding__3bkgC {
  background-color: #ffffff;
  padding-right: 0px;
  padding-left: 0px; }

.ForgottenContainer_forgottenWrapper__2JKpX {
  background-color: #ffffff;
  padding: 32px 40px 52px 40px;
  text-align: center; }

.ForgottenContainer_link__32aPI {
  margin-top: 24px;
  display: block;
  text-align: center; }

.ForgottenContainer_sidebarWrapper__3kOxY {
  padding-left: 0px;
  padding-right: 0px; }

.STEMSearchStep_input__3R_5l {
  padding-top: 24px; }

.STEMSearchStep_button__1QKpz {
  margin-top: 24px; }

.STEMSearchStep_content__2VYvc {
  padding: 40px; }

.STEMSearchStep_title__1FuHi {
  background-color: #4b5468;
  color: #ffffff;
  margin-top: 32px;
  padding: 16px 32px; }

.STEMSearchStep_contentWrapper__3MmgD {
  background-color: white; }

.STEMSearchStep_header__va9vL {
  margin-top: 56px; }

.STEMSearchStep_tooltipButton__vruud {
  cursor: pointer; }

.STEMSearchStep_modalClose__1X-M2 {
  margin-top: 34px; }

.STEMSearchStep_inputHelper__WaAK3 {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  z-index: 2; }

.CancelButtonComponent_cancelButton__8xr7M {
  margin-top: 10px;
  height: 40px;
  width: 140px;
  border: 2px solid #111826;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }

.MedikitModalComponent_modalClose__2IstS {
  margin-top: 34px; }

.MedikitModalComponent_content__20OHu {
  color: #1d1d1d; }

.SpinnerButtonComponent_button__zWMQG {
  padding: 0 52px; }

.AddProviderStep_input__p-RE1 {
  padding-top: 24px; }

.AddProviderStep_button__1tXFW {
  padding: 0 52px; }

.AddProviderStep_buttonCol__Se5L2 {
  text-align: right; }

.AddProviderStep_content__2f2wG {
  padding: 28px 0px 40px 0px; }

.AddProviderStep_title__1azYX {
  background-color: #4b5468;
  color: #ffffff;
  margin-top: 32px;
  padding: 16px 32px; }

.AddProviderStep_contentWrapper__wZsAs {
  background-color: white;
  padding: 40px 40px 0px 40px; }

.AddProviderStep_header__1GCII {
  padding-top: 56px; }

.AddProviderSearchComponent_stemLink__3X4B3 {
  padding-top: 10px; }

.STEMSearchResultsStep_input__1u30W {
  padding-top: 24px; }

.STEMSearchResultsStep_providerName__33B3R {
  padding-top: 32px;
  padding-bottom: 7px; }

.STEMSearchResultsStep_button__2ycWs {
  margin: 40px; }

.STEMSearchResultsStep_hr__1ZCzQ {
  width: 100%;
  border-bottom-color: #D7DBE3;
  margin: 18px 0; }

.STEMSearchResultsStep_tableHeader__G0eu1 {
  padding: 25px 40px;
  background-color: #4b5468;
  margin-top: 30px;
  color: #ffffff; }

.STEMSearchResultsStep_contentWrapper__3ra0F {
  background-color: white;
  padding: 0; }

.STEMSearchResultsStep_resultsWrapper__2vWMt {
  padding-bottom: 100px; }

.STEMSearchResultsStep_contentWrapper__3ra0F div.STEMSearchResultsStep_locationsList__1tJlz:nth-of-type(even) {
  background-color: #f2f4f7; }

.STEMSearchResultsStep_contentBox__1pIBg {
  padding: 0 40px; }

.STEMSearchResultsStep_locationWrapper__1v8KY {
  -webkit-align-items: center;
          align-items: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.STEMSearchResultsStep_header__256RZ {
  margin-top: 56px;
  padding-bottom: 30px; }

.STEMSearchResultsStep_cancel__21L9K {
  text-align: right; }

.STEMSearchResultsStep_cancelButton__13aJe {
  margin-top: 28px;
  height: 40px;
  width: 140px;
  border: 2px solid #111826;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center; }

.AddProviderSearchResultsStep_providerName__7FITC {
  padding-top: 32px;
  padding-bottom: 25px; }

.AddProviderSearchResultsStep_button__1PTGX {
  margin-top: 40px;
  margin-bottom: 40px; }

.AddProviderSearchResultsStep_hr__15mKi {
  width: 100%;
  border-bottom-color: #D7DBE3;
  margin: 0;
  margin-bottom: 10px; }

.AddProviderSearchResultsStep_contentWrapper__2i2R3 {
  background-color: white;
  padding: 0; }

.AddProviderSearchResultsStep_contentBox__RvBrA {
  padding: 0 40px; }

.AddProviderSearchResultsStep_listHeader__3YnKj {
  background-color: #4b5468;
  color: #ffffff;
  padding-top: 25px;
  padding-bottom: 25px; }

.AddProviderSearchResultsStep_tableBody__pwO3J {
  padding-top: 24px; }

.AddProviderSearchResultsStep_header__3WS5p {
  margin-top: 56px;
  padding-bottom: 30px; }

.AddProviderSearchResultsStep_infoLink__2J-fY {
  color: #4054B8;
  font-size: 14px;
  line-height: 24px;
  padding-left: 24px; }

.AddProviderSearchResultsStep_input__1wwlO {
  padding-left: 0;
  margin-top: 8px;
  padding-bottom: 0;
  margin-bottom: 2px; }

.AddProviderSearchResultsStep_inputGroup__3Ze33 {
  margin-bottom: 0px; }

.AddProviderSearchResultsStep_bottomPadding__KBu-o {
  padding-bottom: 40px; }

.MyDetailsContainer_mainWrapper__1Yt1w {
  padding-top: 40px;
  padding-bottom: 128px; }

.PersonalDetailsComponent_detailBlock__rwaCW {
  background-color: #ffffff;
  padding: 30px 40px 40px 40px; }

.PersonalDetailsComponent_detailRow__1NOa0 {
  display: -webkit-flex;
  display: flex;
  padding-top: 14px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.PersonalDetailsComponent_detailItem__3o3WA {
  padding-right: 80px; }

.PersonalDetailsComponent_detailItem__3o3WA:last-child {
  padding-right: 0px; }

.DetailsDisplayComponent_detailWrapper__2EsSv {
  padding-top: 24px; }

.DetailsDisplayComponent_detailBlock__3gsww {
  background-color: #ffffff;
  padding: 30px 40px 40px 40px; }

.DetailsDisplayComponent_fieldRow__3sHc9 {
  padding-top: 24px; }

.DetailsDisplayComponent_button__2KWsa {
  padding: 3px 20px 1px;
  font-size: 14px;
  width: 160px;
  margin-top: 32px; }

.DetailsDisplayComponent_alertWrapper__3pzed {
  margin-bottom: -20px; }

.MobileDetailsEditNumberComponent_mobileRow__182zK {
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.MobileDetailsEditNumberComponent_sendBtnWrapper__JPsVu {
  text-align: right; }

.MobileDetailsEditNumberComponent_sendBtn__1HE_l {
  margin-bottom: 12px;
  width: 145px;
  min-width: 145px;
  padding: 5px 20px 3px; }

.MobileDetailsEditNumberComponent_inputHelper__2lfHy {
  padding-top: 10px; }

.MobileDetailsEditContainer_detailBlock__2bNE5 {
  background-color: #ffffff;
  margin-top: 24px; }

.MobileDetailsEditContainer_detailHeader__2lEoc {
  background-color: #596dd4;
  color: #ffffff;
  padding: 10px 24px 5px 24px; }

.MobileDetailsEditContainer_detailLeft__307xy {
  background-color: #ffffff;
  padding: 30px 0px 40px 40px; }

.MobileDetailsEditContainer_detailRight__3YUvU {
  padding: 58px 40px 40px 40px;
  background-color: #fafbfc;
  height: 100%; }

.MobileDetailsEditContainer_buttonRow__1ssDd {
  margin-top: 32px; }
  .MobileDetailsEditContainer_buttonRow__1ssDd button {
    width: 160px;
    margin-right: 24px;
    padding: 5px 40px 3px; }

.MobileDetailsSMSCodeComponent_verifyRow__lojSM {
  padding-top: 24px; }

.MobileDetailsSMSCodeComponent_inputHelper__312JL {
  padding-top: 10px; }

.AlertModalComponent_modalContent__3954h {
  padding: 2rem 2rem; }

.PasswordDetailsEditContainer_detailBlock__2tHSK {
  background-color: #ffffff;
  margin-top: 24px; }

.PasswordDetailsEditContainer_detailHeader__2UAkJ {
  background-color: #596dd4;
  color: #ffffff;
  padding: 10px 24px 5px 24px; }

.PasswordDetailsEditContainer_detailLeft__xvO5l {
  background-color: #ffffff;
  padding: 0px 0px 40px 40px; }

.PasswordDetailsEditContainer_detailLeftOldPwd__3p3An {
  background-color: #ffffff;
  padding: 30px 0px 0px 40px; }

.PasswordDetailsEditContainer_detailRight__RVchK {
  padding: 25px 40px 40px 40px;
  background-color: #fafbfc;
  height: 100%; }

.PasswordDetailsEditContainer_confirmPwdRow__2OX66 {
  padding-top: 24px; }

.PasswordDetailsEditContainer_errorRow__1qqNO {
  color: #d90432;
  padding-top: 16px; }

.PasswordDetailsEditContainer_buttonRow__3wzhn {
  margin-top: 32px; }
  .PasswordDetailsEditContainer_buttonRow__3wzhn button {
    width: 160px;
    margin-right: 24px;
    padding: 5px 40px 3px; }

.AccountNavComponent_accountMenu__3mPMg {
  width: 100%;
  background-color: #ffffff; }
  .AccountNavComponent_accountMenu__3mPMg a {
    display: block;
    color: #000000;
    padding: 20px 24px;
    border-bottom: 1px solid #d8dbe3; }
  .AccountNavComponent_accountMenu__3mPMg a:last-child {
    border-bottom: none; }

.EmailDetailsEditContainer_modalContent__34Pq2 {
  padding-bottom: 100px; }

.EmailDetailsEditContainer_inputHelper__1VEjJ {
  padding-top: 10px; }

.EmailDetailsEditContainer_buttonRow__IucyR {
  margin-top: 32px; }
  .EmailDetailsEditContainer_buttonRow__IucyR button {
    width: 160px;
    margin-right: 24px;
    padding: 5px 40px 3px; }

.EmailDetailsEditContainer_errorRow__hZolJ {
  color: #d90432;
  padding-top: 16px; }

.EmailDetailsEditContainer_detailBlock__tLvmw {
  background-color: #ffffff;
  margin-top: 24px; }

.EmailDetailsEditContainer_detailHeader__2bDbL {
  background-color: #596dd4;
  color: #ffffff;
  padding: 10px 24px 5px 24px; }

.EmailDetailsEditContainer_detailLeft__1MBXe {
  background-color: #ffffff;
  padding: 30px 0px 40px 40px; }

.EmailDetailsEditContainer_detailRight__1VyWB {
  padding: 58px 40px 40px 40px;
  background-color: #fafbfc;
  height: 100%; }

.EmailDetailsEditContainer_resendWrapper__Ln5rC {
  padding-top: 10px; }
  .EmailDetailsEditContainer_resendWrapper__Ln5rC span:last-child {
    padding-left: 5px; }

.EmailDetailsEditContainer_resendError__J_9bH {
  color: #d90432; }

.MyProvidersContainer_mainWrapper__3kptG {
  padding-top: 40px;
  padding-bottom: 40px; }

.MyProvidersContainer_contentWrapper__dnj9k {
  background-color: white;
  padding: 0; }

.MyProvidersContainer_sectionHeader__2NWaw {
  margin-top: 2em; }

.MyProvidersContainer_detailsBox__2_xyN {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding-left: 0.5em; }
  .MyProvidersContainer_detailsBox__2_xyN h5 {
    margin-bottom: 0px; }
  .MyProvidersContainer_detailsBox__2_xyN p {
    margin-bottom: 1em; }

.MyProvidersContainer_providerName__2dxP1 {
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.MyProvidersContainer_groupHeader__KYs-x {
  padding: 10px 40px;
  background-color: #fafbfc;
  width: 100%; }
  .MyProvidersContainer_groupHeader__KYs-x h4 {
    margin-top: 0.8rem; }

.MyProvidersContainer_awaiting__1zMFc {
  border-left: 4px solid #009e8c; }

.MyProvidersContainer_declined__2xLj- {
  border-left: 4px solid #a60024; }

.MyProvidersContainer_accepted__25hSY {
  border-left: 4px solid #2e92e9; }

.MyProvidersContainer_stemBox__1A4vO {
  margin-top: 1.5em; }

.MyProvidersContainer_buttonDiv__2YVvu {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  display: -webkit-flex;
  display: flex;
  padding-right: 0; }
  .MyProvidersContainer_buttonDiv__2YVvu button {
    margin: auto 0;
    height: 48px; }

.MyProvidersContainer_stemDiv__2427S {
  padding-left: 0; }

.MyProvidersContainer_resultsWrapper__1xkBl {
  background-color: #ffffff;
  margin-bottom: 0; }

.MyProvidersContainer_providerTable__3236c {
  margin-bottom: 0px; }

.MyProvidersContainer_th1__2J3OG {
  width: 160px;
  min-width: 160px; }

.MyProvidersContainer_th2__3FmXY {
  width: auto;
  min-width: 400px; }

.MyProvidersContainer_th3__3c5iL {
  width: 255px;
  min-width: 255px; }

.MyProvidersContainer_noConnectedWrapper__3aJIf {
  background-color: #ffffff;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 15px;
  padding: 25px 0px; }

.MyProvidersContainer_addBtn__V9wvI {
  width: 220px; }

.MyUsersContainer_mainWrapper__2lcXd {
  padding-top: 40px;
  padding-bottom: 128px; }

.MyUsersContainer_alertsContainer__3N21w {
  margin: 0; }

.ConnectedUsersContainer_resultsWrapper__2wht6 {
  background-color: #ffffff; }

.ConnectedUsersContainer_th1__3mjMG {
  width: 250px; }

.ConnectedUsersContainer_th2__2wbAH {
  width: auto; }

.ConnectedUsersContainer_th3__2_Q2K {
  width: 100px;
  min-width: 100px; }

.MyRequestsContainer_mainWrapper__gMc1k {
  padding-top: 40px;
  padding-bottom: 128px; }

.MyRequestsContainer_declinedPanel__2Ds6U {
  border-left: 4px solid #E62E43;
  background-color: white;
  margin-top: 10px;
  padding: 1em 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .MyRequestsContainer_declinedPanel__2Ds6U h4 {
    margin-top: 10px; }

.MyRequestsContainer_acceptedPanel__2uiDv {
  border-left: 4px solid #009E8C;
  background-color: white;
  margin-top: 10px;
  padding: 1em 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .MyRequestsContainer_acceptedPanel__2uiDv h4 {
    margin-top: 10px; }
  .MyRequestsContainer_acceptedPanel__2uiDv a {
    margin-top: 8px;
    display: inline-block; }

.MyRequestsContainer_tooltipIcon__qEmYH {
  height: 15px;
  width: 15px;
  margin-left: 7px; }

.MyRequestsContainer_closeIcon__1AKr7 {
  height: 15px;
  width: 15px;
  margin-top: 14px;
  margin-left: 12px; }

.MyRequestsContainer_rows__1wdSJ {
  padding-bottom: 14px;
  margin: 0 23px; }

.MyRequestsContainer_buttonDiv__15K2X {
  padding-top: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .MyRequestsContainer_buttonDiv__15K2X button {
    margin: auto 0;
    height: 48px; }
  .MyRequestsContainer_buttonDiv__15K2X span {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 36px;
    -webkit-justify-content: space-around;
            justify-content: space-around; }

.MyRequestsContainer_contentWrapper__2WeYR {
  background-color: white;
  padding: 0 12px 12px 12px;
  margin: 0;
  margin-top: 2em; }
  .MyRequestsContainer_contentWrapper__2WeYR h4 {
    padding-bottom: 12px;
    margin-top: 40px; }

.MyRequestsContainer_resultsWrapper__ZIwix {
  background-color: white;
  padding: 0 12px 12px 12px;
  margin: 0; }
  .MyRequestsContainer_resultsWrapper__ZIwix h4 {
    padding-bottom: 12px;
    margin-top: 40px; }
  .MyRequestsContainer_resultsWrapper__ZIwix th:first-of-type, .MyRequestsContainer_resultsWrapper__ZIwix td:first-of-type {
    padding-left: 20px; }

.MyRequestsContainer_th1__3r7OK {
  width: 160px;
  min-width: 160px; }

.MyRequestsContainer_th2__gfHk_ {
  width: auto;
  min-width: 400px; }

.MyRequestsContainer_th3__1uDYy {
  width: 255px;
  min-width: 255px; }

.PracticeManagerInfoPanel_infoPanel__1eds7 {
  border-left: 4px solid #4999D2;
  background-color: #ffffff;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 16px 0;
  position: relative; }

.PracticeManagerInfoPanel_infoIcon__3ZjI8 {
  height: 41px;
  width: 41px;
  color: #4999D2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px; }

.ManageUserContainer_mainWrapper__1Py3N {
  padding-top: 40px;
  padding-bottom: 128px; }

.ManageUserContainer_contentBox__3ZuRm {
  background-color: #ffffff;
  padding: 24px 20px; }

.ManageUserContainer_providerHeader__1a5lA {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 24px 0; }
  .ManageUserContainer_providerHeader__1a5lA span {
    margin-right: 40px; }

.ManageUserContainer_th1__1dT1L {
  width: 160px;
  min-width: 160px; }

.ManageUserContainer_th2__1AQft {
  width: auto;
  min-width: 400px; }

.ManageUserContainer_th3__1sgYE {
  width: 255px;
  min-width: 255px; }

.ManageUserContainer_table__3O0Vo {
  background-color: #ffffff; }
  .ManageUserContainer_table__3O0Vo th, .ManageUserContainer_table__3O0Vo td {
    padding: 12px 0px; }
  .ManageUserContainer_table__3O0Vo th:first-of-type, .ManageUserContainer_table__3O0Vo td:first-of-type {
    padding-left: 20px; }

.ManageUserContainer_select__2_uZS {
  padding-right: 40px; }
  .ManageUserContainer_select__2_uZS .ManageUserContainer_selectChevron__iDmI4 {
    right: 50px; }

.TokenVerifiedComponent_contentWrapper__2eU68 {
  padding-top: 150px;
  text-align: center; }
  .TokenVerifiedComponent_contentWrapper__2eU68 h2 {
    padding-top: 32px; }

.TokenVerifiedComponent_infoIcon__1Lwso {
  height: 82px;
  width: 82px;
  color: #000000; }

.TokenVerifiedComponent_button__3UKPg {
  margin-top: 50px; }

.TokenExpiredComponent_contentWrapper__hEOae {
  padding-top: 150px;
  text-align: center; }
  .TokenExpiredComponent_contentWrapper__hEOae h2 {
    padding-top: 32px; }

.TokenExpiredComponent_infoIcon__2qkKB {
  height: 82px;
  width: 82px;
  color: #000000; }

.TokenExpiredComponent_button__1r4p6 {
  margin-top: 50px;
  width: 305px; }

.VerifyEmailContainer_errorWrapper__3hybC {
  padding-top: 50px; }

.Main_bottomSpacer__1II1j {
  padding-bottom: 40px; }

.Main_contentWrapper__1A5wu {
  padding-top: 80px;
  padding-bottom: 100px; }

.PrimaryNav_iconWrapper__1tcFD {
  color: #000000;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
  margin-top: -3px; }

.PrimaryNav_chevronWrapper__1ubZ4 {
  color: #000000;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 10px;
  margin-top: 3px; }

.PrimaryNav_userWrapper__e2q0A {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  cursor: pointer;
  position: relative; }

.PrimaryNav_userMenu__G-5re {
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 500; }
  .PrimaryNav_userMenu__G-5re ul {
    border: 1px solid #d8dbe3;
    width: 200px;
    background-color: #ffffff; }
    .PrimaryNav_userMenu__G-5re ul li {
      padding: 1px 10px 5px 10px;
      border-bottom: 1px solid #d8dbe3; }
      .PrimaryNav_userMenu__G-5re ul li a {
        color: #000000;
        display: block; }
    .PrimaryNav_userMenu__G-5re ul li:first-child {
      padding-top: 9px; }

.Footer_footer-text__17tXs {
  display: inline-block; }

.Footer_policies__3d-WQ {
  float: right; }

