@import '~styles/variables';

.providerName {
  padding-top: 32px;
  padding-bottom: 25px;
}

.button {
  margin-top: 40px;
  margin-bottom: 40px;
}

.hr {
  width: 100%;
  border-bottom-color: #D7DBE3;;
  margin: 0;
  margin-bottom: 10px;
}


.contentWrapper {
  background-color: white;
  padding: 0;
}

.contentBox {
  padding: 0 40px;
}

.listHeader {
  background-color: $medikit-neutral6;
  color: $medikit-white;
  padding-top: 25px;
  padding-bottom: 25px;
}

.tableBody {
  padding-top: 24px;
}

.header {
  margin-top: 56px;
  padding-bottom: 30px;
}

.infoLink {
  color: #4054B8;
  font-size: 14px;
  line-height: 24px;
  padding-left: 24px;
}

.input {
  padding-left: 0;
  margin-top: 8px;
  padding-bottom: 0;
  margin-bottom: 2px;


}

.inputGroup {
  margin-bottom: 0px;
}

.bottomPadding {
  padding-bottom: 40px;
}