.alertWrapper {
  padding-bottom: 20px;
}

.alertTitle {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 16px;
}

.closeIcon {
  cursor: pointer;
}