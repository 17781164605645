@import '~styles/variables';

.accountMenu {
  width: 100%;
  background-color: $medikit-white;

  a {
    display: block;
    color: $medikit-black;
    padding: 20px 24px;
    border-bottom: 1px solid $medikit-neutral4;
  }

  a:last-child {
    border-bottom: none;
  }
}
