@import '~styles/variables';

.infoPanel {
  border-left: 4px solid #4999D2;
  background-color: $medikit-white;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;

  padding: 16px 0;
  position: relative;
}

.infoIcon {
  height: 41px;
  width: 41px;

  color: #4999D2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}
