@import '~styles/variables';

.welcome {
  text-align: center;

  h2 {
    color: #000000;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: 48px;
  }

  .iconWrapper {
    height: 88px;
    width: 88px;

    margin-top: 143px;
    margin-bottom: 53px;

    margin-left: auto;
    margin-right: auto;
  }

  .welcomeInfo {
    line-height: 24px;
    max-width: 392px;
    margin: 0 auto;
    font-size: 16px;
  }

  .registrationProcessNote {
    width: 502px;
    height: 48px;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 80px;
    text-align: center;
    color: #585D67;
  }
}



