$medikit-white: #ffffff;
$medikit-black: #000000;

$medikit-primary: #e62e43;
$medikit-primary-dark: #d90432;
$medikit-primary-darker: #a60024;

$medikit-accent: #2e92e9;
$medikit-accent-dark: #1378d1;
$medikit-accent-darker: #004887;

$medikit-highlight1: #5e50a1;
$medikit-highlight1-dark: #4e3c9e;
$medikit-highlight1-darker: #312669;

$medikit-highlight2: #596dd4;
$medikit-highlight2-dark: #4054b8;
$medikit-highlight2-darker: #2b3c8f;

$medikit-highlight3: #009e8c;
$medikit-highlight3-dark: #008575;
$medikit-highlight3-darker: #00665a;

$medikit-highlight4: #009fab;
$medikit-highlight4-dark: #00838c;
$medikit-highlight4-darker: #00666e;

$medikit-neutral1: #fafbfc;
$medikit-neutral2: #f2f4f7;
$medikit-neutral3: #e6e9f0;
$medikit-neutral4: #d8dbe3;
$medikit-neutral5: #111826;
$medikit-neutral6: #4b5468;
$medikit-neutral7: #8c92a1;

$medkit-alert: #e01049;
$medkit-alert-light: #fae9ed;
$medkit-success: #00a66f;
$medkit-success-light: #e5f6f1;