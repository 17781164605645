@import '~styles/variables';

.tableHeader {
  color: $medikit-white;
  font-size: 13px;
  line-height: 17px;
  font-weight: 100;
  border-spacing: 0;
  width: 100%;

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
}

.datesTopCell {
  background-color: $medikit-highlight1-darker;
  max-width: 240px;
  width: 240px;
}

.datesBottomCell {
  background-color: $medikit-highlight1-dark;
  max-width: 70px;
  width: 70px;
}

.datesAssessCell {
  background-color: $medikit-highlight1-dark;
  max-width: 100px;
  width: 100px;
}

.providerTopCell {
  background-color: $medikit-highlight4-darker;
  max-width: 208px;
  width: 208px;
}

.providerBottomCell {
  background-color: $medikit-highlight4-dark;
}

.statusCell {
  background-color: $medikit-neutral5;
  max-width: 119px;
  width: 119px;
}

.chequeCell {
  background-color: $medikit-neutral6;
  max-width: 112px;
  width: 112px;
}

.serviceCell {
  background-color: $medikit-neutral6;
  max-width: 65px;
  width: 65px;
}

.mbsItemNoCell {
  background-color: $medikit-neutral6;
  max-width: 60px;
  width: 60px;
}

.mbsFeeCell {
  background-color: $medikit-neutral6;
  max-width: 65px;
  width: 65px;
}

.mplCell {
  background-color: $medikit-neutral6;
  max-width: 84px;
  width: 84px;
}

.benefitCell {
  background-color: $medikit-neutral6;
  max-width: 84px;
  width: 84px;
}

.itemCell {
  background-color: $medikit-neutral6;
}
