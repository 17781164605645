.terms-and-conditions {
    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.3em;
    }

    ol li span {
        position: relative;
        left: 30px;
    }
}
