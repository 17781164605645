.titleSection {
  text-align: center;
  margin-bottom: 20px;
}

.button {
  margin-top: 10px;
  height: 48px;
  margin-left: 10px;
}

.button2 {
  margin-top: 10px;
  height: 48px;
  margin-left: 15px;
}

