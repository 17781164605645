@import '~styles/variables';

.alert {
    span {
        font-size: 21px;
        font-weight: 500;
    }
}

.contentWrapper {
    padding-top: 48px;
    padding-bottom: 30px;
}

.content {
    background-color: $medikit-neutral1;
    padding: 32px 40px;
}

.terms {
    border: 2px solid $medikit-neutral3;
    overflow: scroll;
    padding: 8px;
    max-height: calc(100vh - 430px);
}

.checkbox-agree {
    float: left;
    width: initial;
    margin-top: 11px;
    margin-bottom: 11px;
}

.checkbox-wrapper {
    margin-bottom: 0 !important;
}

.footer {
    overflow: overlay;
    margin-top: 30px;

    button {
        float: right;
    }
}

/*
    This handles setting the opacity and cursor of the checkbox for the
    terms and conditions agreement. It does not set the cursor or opacity
    of the hyperlinks to the various policies within the checkbox's label.

    !important must be used as the specificity of this rule is superceded
    by that of some of Medikit's rules.
*/
:global(.input-checkbox):local(.disabled) span {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: initial !important;
}
