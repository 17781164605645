@import '~styles/variables';

.input {
  padding-top: 24px;
}

.button {
  padding: 0 52px;
}

.buttonCol {
  text-align: right;
}

.content {
  padding: 28px 0px 40px 0px;
}

.title {
  background-color: $medikit-neutral6;
  color: $medikit-white;
  margin-top: 32px;
  padding: 16px 32px;
}

.contentWrapper {
  background-color: white;
  padding: 40px 40px 0px 40px;
}

.header {
  padding-top: 56px;
}

