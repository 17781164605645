@import '~styles/variables';

.tileWrapper {
  padding: 0;
}

.tileWrapper:first-of-type {
  padding-right: 24px;
}

.tileWrapper:last-of-type {
  padding-left: 24px;
}

.tile {
  background-color: $medikit-white;
  margin-top: 24px;
  padding: 55px 0px;
}

.hiddenTile {
  margin-top: 24px;
  padding: 55px 0px;
}

.iconWrapper {
  height: 64px;
  width: 64px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.link {
  text-decoration: none;
  color: #1d1d1d;
}