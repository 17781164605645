@import '~styles/variables';

.step1Wrapper {
  padding-top: 40px;
}

.roleRow {
  margin-top: 30px;
  padding-bottom: 100px;
}

.introText {
  background-color: $medikit-white;
  padding: 20px 32px;
  margin-bottom: 30px;
}