@import '~styles/variables';

.pp-provider-select {
    .input-group {
        input[type=text]:disabled {
            background-color: $medikit-white;
        }

        .icon.clear-btn {
            left: auto;
            right: .7rem;
            bottom: 1.2rem;
            width: 10px;
            height: 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .selected-provider-wrapper {
        position: absolute;
        left: 2.5rem;
        bottom: 0.7rem;
    }

    .selected-multi-provider-wrapper {
        position: absolute;
        left: 2.5rem;
        bottom: 0.7rem;
        font-size: 14px;
        background-color: $medikit-neutral2;
        padding-left: 5px;
        padding-right: 35px;
        border-radius: 1px;

        .icon.clear-btn {
            right: .5rem;
            bottom: .5rem;
            width: 8px;
            height: 8px;
        }
    }
}

.pp-provider-list-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid $medikit-neutral3;
    position: absolute;
    background: #fff;
    z-index: 9;
    width: 100%;
    max-height: 280px;
    overflow: auto;
}

.pp-listed-provider {
    cursor: pointer;
    border-bottom: 1px solid $medikit-neutral3;
    padding: 10px;
    margin-top: 0;
    display: flex;

    .l-col {
    }

    .r-col {
        padding-left: 20px;
    }

    &:hover {
        background-color: $medikit-neutral2;
    }
}

.pp-provider-multilist-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    width: 100%;
    max-height: 400px;
    overflow: auto;

    .pp-listed-provider-wrapper {
        border-top: 2px solid $medikit-neutral3;
        border-left: 2px solid $medikit-neutral3;
        border-right: 2px solid $medikit-neutral3;
        &:nth-child(even) {border-left: 1px solid $medikit-neutral3;}
        &:nth-child(odd) {border-right: 1px solid $medikit-neutral3;}
        &:first-child, &:nth-child(2) {border-top: 2px solid $medikit-neutral4;}
        &:last-child, &:nth-last-child(2) {border-bottom: 2px solid $medikit-neutral4;}

        @media (max-width: 991px) {
            &:nth-child(2) {border-top: 2px solid $medikit-neutral3;}
            &:nth-child(even) {border-left: 2px solid $medikit-neutral3;}
            &:nth-child(odd) {border-right: 2px solid $medikit-neutral3;}
        }
    }

    .pp-listed-provider {
        cursor: default;
        border-bottom: none;

        .input-group {
            width: initial;
            padding-top: 3px;

            .input-checkbox {
                padding-right: 12px;
            }
        }
    }
}


