.pp-main {
  background-color: $medikit-neutral3;
  //Add calc to make footer sticky, allowing for size of header and footer (72px each)
  min-height: calc(100vh - 72px - 72px);

  &.with-spinner {
    min-height: calc(100vh - 72px);
  }
}

.pp-search-criteria-wrapper {
  padding-top: 10px;
}

.search-btn-row {
  .search-btn-wrapper {
    padding-top: 32px;

    .input-group {
      text-align: center;
    }
  }
}

.pagination-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 140px;
}

// Temporary change to remove padding from top of footer
.footer-wrapper {
  padding-top:0px;
  //Add attributes to make sticky footer
  width: 100%;
  line-height: 72px;
  height: 72px;
}