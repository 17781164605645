@import '~styles/variables';

.input {
  padding-top: 24px;
}

.button {
  margin-top: 24px;
}

.content {
  padding: 40px;
}

.title {
  background-color: $medikit-neutral6;
  color: $medikit-white;
  margin-top: 32px;
  padding: 16px 32px;
}

.contentWrapper {
  background-color: white;
}

.header {
  margin-top: 56px;
}

.tooltipButton {
  cursor: pointer;
}

.modalClose {
  margin-top: 34px;
}

.inputHelper {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  z-index: 2;
}