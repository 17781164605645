@import '~styles/variables';

.contentWrapper {
  padding-top: 80px;
  padding-bottom: 100px;

  hr {
    margin: 7px 0;
  }
}

.content {
  background-color: $medikit-white;
}

.colPadding{
  background-color: $medikit-white;
  padding-right: 0px;
  padding-left: 0px;
}

.loginWrapper {
  background-color: $medikit-white;
  padding: 32px 40px 30px 40px;
}

.username {
  padding-top: 25px;
  margin-bottom: 24px;
}

.password {
  padding-top: 24px;
}

.copyWrapper {
  padding-top: 44px;
  padding-left: 63px;
  padding-right: 50px;

  background-color: $medikit-neutral2;

  h4 {
    letter-spacing: -0.22px;
    line-height: 32px;
    height: 77px;

    font-size: 20px;
  }

  ul {
    margin-left: -34px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 28px;
  }
}

.image {
  img {
    height: 100%;
  }
  padding: 0;
}

.link {
  margin-top: 24px;
  display: block;
  text-align: center;
}

.button {
  margin-top: 20px;
  height: 48px;
}

.inputHelper {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  z-index: 2;
}

.message {
  margin-left: 140px;
}

.errorLink {
  text-decoration: underline;
  color: $medikit-primary;

  &:hover {
    text-decoration: none;
  }
}