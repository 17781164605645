@import '~styles/variables';

.emptyRowStyles {
  height: 80px;
  width: 100%;
  text-align: center;
}

.resultsWrapper {
  background-color: $medikit-white;
  margin-bottom: 0px;
}

.th1 {
  width: 130px;
  min-width: 130px;
}

.th2 {
  width: 157px;
  min-width: 157px;
}

.th3 {
  width: auto;
  min-width: 320px;
}

.th4 {
  width: 160px;
  min-width: 160px;
}

.th5 {
  width: 150px;
  min-width: 150px;
}