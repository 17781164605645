@import '~styles/variables';

.searchCriteriaWrapper {
  padding-top: 30px;
}

.searchCriteriaInner {
  padding: 40px 40px 50px 40px;
  background-color: $medikit-white;
}

.dateSearchRow {
  padding-top: 1rem;
}

.helpText {
  font-size: 14px;
}