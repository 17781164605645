@import '~styles/variables';

.detailBlock {
  background-color: $medikit-white;
  padding: 30px 40px 40px 40px;
}

.detailRow {
  display: flex;
  padding-top: 14px;
  justify-content: flex-start;
}

.detailItem {
  padding-right: 80px;
}

.detailItem:last-child {
  padding-right: 0px;
}