@import '~styles/variables';

.topRow {
  background-color: $medikit-white;
  padding-top: 20px;
  padding-bottom: 40px;
  margin-top: 20px;
}

.topRow:first-child {
  margin-top: 0px;
}

.dateIcon {
  height: 12px;
  width: 12px;
  margin-right: 16px;
  margin-bottom: 5px;

  path {
    stroke-width: 1;
  }
}

.titleRow {
  background-color: $medikit-white;
}

.detailRow {
  background-color: $medikit-white;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pendingIcon {
  margin-left: -7px;
  margin-bottom: 4px;
}

.subDetailRow {
  background-color: $medikit-white;
  padding-bottom: 20px;
}

.eclipseRow {
  background-color: $medikit-white;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.benefitRow {
  background-color: $medikit-neutral1;
  padding-bottom: 20px;
  padding-top: 20px;
  align-items: center;

  .left {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .right {
    text-align: right;
    flex: 0 0 32%;
    max-width: 32%;
  }

  .tba {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

.benefitTitle {
  font-weight: 500;
  font-size: 18px;
}

.detailsBtn {
  padding: 3px 20px 1px;
}

.largeNumbers {
  font-size: 38px;
  position: relative;
  top: 10px;
  padding-right: 16px;
  font-weight: 500;
}

.dollarSign {
  font-size: 20px;
  position: relative;
  top: -5px;
}

.statusLink {
  color: $medikit-highlight3;
  padding-right: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.lightGrey {
  opacity: 0.7;
}

.lightBold {
  font-weight: 500;
}
