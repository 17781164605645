@import '~styles/variables';

.mainWrapper {
  padding-top: 40px;
  padding-bottom: 128px;
}

.contentBox {
  background-color: $medikit-white;
  padding: 24px 20px;
}

.providerHeader {
  display: flex;
  justify-content: space-between;

  margin: 24px 0;

  span {
    margin-right: 40px;
  }
}

.th1 {
  width: 160px;
  min-width: 160px;
}

.th2 {
  width: auto;
  min-width: 400px;
}

.th3 {
  width: 255px;
  min-width: 255px;
}

.table {
  background-color: $medikit-white;
  th, td {
    padding: 12px 0px;
  }
  th:first-of-type, td:first-of-type {
    padding-left: 20px;
  }
}

.select {
  padding-right: 40px;

  .selectChevron {
    right: 50px;
  }
}



