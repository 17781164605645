@import '~styles/variables';

.alerts {
    background-color: $medikit-white;
    border-left: $medikit-highlight3 solid 4px;
    padding: 16px 32px 16px 40px;
    margin-bottom: 8px;

    h4 {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.75px;
        line-height: 24px;
    }

    .navLink {
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        a {
            vertical-align: middle;
        }
    }

}
