@import '~styles/variables';

.claimsWrapper {
  padding-top: 40px;
}

.middle {
  padding-top: 50px;
  padding-bottom: 20px;
}

.bottomHalf {
  background-color: $medikit-neutral2;
}

.resultsWrapper {
  position: relative;
}
