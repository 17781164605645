@import '~styles/variables';

.datesBottomCell {
  max-width: 70px;
  width: 70px;
}

.datesAssessCell {
  max-width: 100px;
  width: 100px;
}

.providerTopCell {
  max-width: 208px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.providerName {
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chequeCell {
  max-width: 112px;
  width: 112px;
}

.serviceCell {
  max-width: 65px;
  width: 65px;
}

.mbsItemNoCell {
  max-width: 60px;
  width: 60px;
}

.mbsFeeCell {
  max-width: 65px;
  width: 65px;
}

.mplCell {
  max-width: 84px;
  width: 84px;
}

.benefitCell {
  max-width: 84px;
  width: 84px;
}

.statusCell {
  max-width: 119px;
  width: 119px;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.statusChevron {
  padding-left: 5px;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.statusChevronOpen {
  transform: rotate(180deg);
  transform-origin: center center;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}