@import '~styles/variables';

.titleWrapper {
  background-color: $medikit-white;
  padding: 40px 40px 0px 40px;
}

.copyWrapper {
  padding: 25px 40px 0px 40px;
  background-color: $medikit-neutral1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.radioWrapper {
  background-color: $medikit-white;
  padding: 0px 40px 0px 40px;

  .radioLabel {
    margin-bottom: 10px;
  }
}

.radioHelp {
  padding-left: 30px;
}

.radioCopyWrapper {
  padding: 0px 40px 0px 40px;
  background-color: $medikit-neutral1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.createAcctWrapper {
  background-color: $medikit-white;
  padding: 10px 40px 0px 40px;
}

.fieldsWrapper {
  background-color: $medikit-white;
  padding: 0px 40px 0px 40px;
}

.termsConditions {
  padding-top: 10px;
  padding-bottom: 40px;
}

.contentWrapper {
  padding-top: 32px;
  padding-bottom: 100px;
}















