.mobileRow {
  align-items: flex-end;
}

.sendBtnWrapper {
  text-align: right;
}

.sendBtn {
  margin-bottom: 12px;
  width: 145px;
  min-width: 145px;
  padding: 5px 20px 3px;
}

.inputHelper {
  padding-top: 10px;
}
