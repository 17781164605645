@import '~styles/variables';

.resultsWrapper {
  background-color: $medikit-white;
}

.th1 {
  width: 250px;
}

.th2 {
  width: auto;
}

.th3 {
  width: 100px;
  min-width: 100px;
}
