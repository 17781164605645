@import '~styles/variables';

.mainWrapper {
  padding-top: 40px;
  padding-bottom: 128px;
}

.declinedPanel {
  border-left: 4px solid #E62E43;
  background-color: white;
  margin-top: 10px;
  padding: 1em 1em;
  display: flex;
  justify-content: space-between;

  h4 {
    margin-top: 10px;
  }
}

.acceptedPanel {
  border-left: 4px solid #009E8C;;
  background-color: white;
  margin-top: 10px;
  padding: 1em 1em;
  display: flex;
  justify-content: space-between;
  h4 {
    margin-top: 10px;
  }
  a {
    margin-top: 8px;
    display: inline-block;
  }
}

.tooltipIcon {
  height: 15px;
  width: 15px;
  margin-left: 7px;
}

.closeIcon {
  height: 15px;
  width: 15px;
  margin-top: 14px;
  margin-left: 12px;
}

.rows {
  padding-bottom: 14px;
  margin: 0 23px;
}



.buttonDiv {
  padding-top: 18px;
  display: flex;
  justify-content: flex-end;
  button {
    margin: auto 0;
    height: 48px;
  }
  span {
    display: flex;
    flex-direction: column;
    margin-right: 36px;
    justify-content: space-around;
  }

}

.contentWrapper {
  background-color: white;
  padding: 0 12px 12px 12px;
  margin: 0;
  margin-top: 2em;
  
  h4 {
    padding-bottom: 12px;
    margin-top: 40px;
  }
}

.resultsWrapper {
  background-color: white;
  padding: 0 12px 12px 12px;
  margin: 0;

  h4 {
    padding-bottom: 12px;
    margin-top: 40px;
  }

  th:first-of-type, td:first-of-type {
    padding-left: 20px;
  }
}

.th1 {
  width: 160px;
  min-width: 160px;
}

.th2 {
  width: auto;
  min-width: 400px;
}

.th3 {
  width: 255px;
  min-width: 255px;
}
