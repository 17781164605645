@import '~styles/variables';

.sectionWrapper {
  background-color: $medikit-white;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 30px;
}

.detailRow {
  padding-top: 5px;
  padding-bottom: 10px;
}

.eclipseRow {
  padding-top: 20px;
}
