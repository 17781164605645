@import '~styles/variables';

.emptyRowStyles {
  height: 80px;
  width: 100%;
  text-align: center;
  background-color: $medikit-white;
  display: flex;
  align-items: center;
  justify-content: center;
}
