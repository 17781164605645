@import '~styles/variables';

.stepsWrapper {
  background-color: $medikit-white;
  padding-bottom: 20px;
}

.alertWrapper {
  padding-top: 30px;
}
