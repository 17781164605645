@import '~styles/variables';

.contentWrapper {
  padding-top: 80px;
  padding-bottom: 100px;
}

.colPadding{
  background-color: $medikit-white;
  padding-right: 0px;
  padding-left: 0px;
}

.forgottenWrapper {
  background-color: $medikit-white;
  padding: 32px 40px 52px 40px;
  text-align: center;
}

.link {
  margin-top: 24px;
  display: block;
  text-align: center;
}

.sidebarWrapper {
  padding-left: 0px;
  padding-right: 0px;
}


