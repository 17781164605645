.identityWrapper {
  padding-top: 80px;
}

.inputGroupSmallTwoThirds {
  width: calc(66% - 3px);
}

.inputGroupSmallOneThird {
  margin-left: 6px;
  width: calc(33% - 3px);
}
