@import '~styles/variables';

.input {
  padding-top: 24px;
}

.providerName {
  padding-top: 32px;
  padding-bottom: 7px;
}

.button {
  margin: 40px;
}

.hr {
  width: 100%;
  border-bottom-color: #D7DBE3;;
  margin: 18px 0;
}

.tableHeader {
  padding: 25px 40px;
  background-color: $medikit-neutral6;
  margin-top: 30px;
  color: $medikit-white;
}

.contentWrapper {
  background-color: white;
  padding: 0;
}

.resultsWrapper {
  padding-bottom: 100px;
}

.contentWrapper div.locationsList:nth-of-type(even) {
  background-color: $medikit-neutral2;
}

.contentBox {
  padding: 0 40px;
}

.locationWrapper {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header {
  margin-top: 56px;
  padding-bottom: 30px;
}

.cancel {
  text-align: right;
}

.cancelButton {
  margin-top: 28px;
  height: 40px;
  width: 140px;
  border: 2px solid #111826;
  border-radius: 1px;


  background-color: rgba(255,255,255,0);

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}


