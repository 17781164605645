
.modalClose {
  margin-top: 34px;
}

.content {
  color: #000000;
  text-align: left;
}

.DrRegisterModal {
  width: 859px;
  height: 690px;
}

.noteMsg {
  color: #585D67;
}

.modalCloseBtn {
  top: 24px;
}

.downloadFormBtn {
  width: 255px;
  height: 50px;
}

.downloadFormNote {
  font-size: 14px;
  line-height: 24px;
  color: #585D67;
}

.selfServiceMailId{
  color: #596dd4;
  text-decoration: underline;
  display: inline;
}
