.linksWrapper {
  display: inline-block;
}

.pagination {
  text-align: center;
}

.paginationList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.isCurrent {
  color: black;
  text-decoration: none;
  font-weight: bold;
  cursor: default;
}

button.navButton {
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
  &:focus {
    outline:0;
  }
}