@import '~styles/variables';

.errorWrapper {
  padding-top: 50px;
}





