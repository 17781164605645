.label {
  margin-bottom: 8px;
  font-size: 16px;
  display: block;
  width: 100%;
}

.form-error {
  color: $medikit-primary;
}

input {
  outline: none;
}

input[type=text].error,
input[type=password].error {
  border-color: $medikit-primary;
}

.input-group .spinner {
  right: 12px;
  top: 48px;
}

.input-group {
  margin-bottom: 12px;
}
