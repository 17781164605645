.table {
  thead {
    background-color: #4c5569;
    color: $medikit-white;
    tr {
      height: 72px;

      th {
        vertical-align: middle;
        font-weight: normal;
      }
    }
  }

  &.lifted-table {
    margin-top: -72px;
    table-layout: fixed;
  }

  td {
    vertical-align: middle;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $medikit-neutral1;
}